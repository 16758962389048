define("ember-svg-jar/inlined/CopyRow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans\" d=\"M4 0C1.795 0 0 1.795 0 4v13c0 2.205 1.795 4 4 4h5v-2H4a2 2 0 01-2-2V4a2 2 0 012-2h4.281C9.002 2.184 9 3.05 9 3.938V6c0 .249.099.481.25.656C9.809 5.117 11.271 4 13 4h.063c-.178-.174-.355-.354-.532-.531-.248-.246-.51-.475-.75-.719C10.07 1.003 9.063 0 8 0H4zm10 5c-2.205 0-4 1.795-4 4v13c0 2.205 1.795 4 4 4h8c2.205 0 4-1.795 4-4v-9c0-1.062-.973-2.07-2.719-3.781-.244-.24-.504-.502-.75-.75-.248-.246-.51-.475-.75-.719C20.07 6.003 19.063 5 18 5h-4zm0 2h4.281c.721.184.719 1.05.719 1.938V11c0 .551.449 1 1 1h2c.998 0 2 .005 2 1v9a2 2 0 01-2 2h-8a2 2 0 01-2-2V9a2 2 0 012-2zM4 8v2h5V8H4zm0 3v2h5v-2H4zm10 2v2h8v-2h-8zM4 14v2h5v-2H4zm10 2v2h6v-2h-6zm0 3v2h8v-2h-8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "25",
      "height": "25",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});