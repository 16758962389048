define("ember-svg-jar/inlined/Checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M55.826 44.762a.565.565 0 00-.747 0l-7.275 6.734-3.064-1.134a.552.552 0 00-.608.875l3.362 3.949a.554.554 0 00.418.196l.058-.002a.555.555 0 00.379-.21l7.535-9.663a.556.556 0 00-.058-.745zm-7.915 10.434v.002-.002z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "11",
      "viewBox": "44 44.5 12 11"
    }
  };
  _exports.default = _default;
});