define("ember-svg-jar/inlined/MovePencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans\" d=\"M20.094.25a2.245 2.245 0 00-1.625.656l-1 1.031 6.593 6.625 1-1.03a2.319 2.319 0 000-3.282L21.75.937A2.36 2.36 0 0020.094.25zm-3.75 2.594l-1.563 1.5 6.875 6.875L23.25 9.75l-6.906-6.906zM13.78 5.438L2.97 16.155a.975.975 0 00-.5.625L.156 24.625a.975.975 0 001.219 1.219l7.844-2.313a.975.975 0 00.781-.656l10.656-10.563L18 9.656h-.031l-1.782-1.812-2.406-2.407zm-9.625 12.5l.532.53 2.593.22.125 2.53.657.657-4.22 1.219-.937-.938 1.25-4.218z\" color=\"#000\" overflow=\"visible\" font-family=\"Bitstream Vera Sans\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});