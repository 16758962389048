define("@movenium/ember-movenium/utils/sumrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSums = getSums;

  function getSums(data, columns, form, collector, forms) {
    var pdfSumrow = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var summableFieldTypes = ['date', 'hours', 'number', 'checkbox', 'decimal', 'price', 'big_price', 'minutes', 'dropdown_values'];
    var summableFields = forms.fieldArray(form).filter(function (field) {
      return summableFieldTypes.includes(field.type);
    });
    if (!summableFields) return;
    var sums = {};
    data.forEach(function (item) {
      summableFields.forEach(function (field) {
        if (!item) return;
        var cell = item[field.name];

        switch (field.type) {
          case 'checkbox':
            if (!sums[field.name]) sums[field.name] = [0, 0];
            cell ? sums[field.name][0]++ : sums[field.name][1]++;
            break;

          case 'dropdown_values':
            if (!sums[field.name]) sums[field.name] = [];
            if (cell) sums[field.name].push(cell);
            break;

          case 'date':
            if (Ember.typeOf(cell) === 'string' && cell.substring(0, 6) === 'count:') {
              sums[field.name] += parseInt(cell.substring(6), 10);
            } else {
              sums[field.name] = '';
              return;
            }

            break;

          default:
            if (!sums[field.name]) sums[field.name] = 0;
            sums[field.name] += cell;
        }
      });
    });
    var sumRow = {};
    columns.forEach(function (column) {
      if (column.external_type === 'row_info.created') return;
      var field = column.external_type;
      var dropdownSums = [];

      if (sums[field]) {
        if (column.type === 'checkbox') {
          sumRow[field] = collector.format(true, column, null, data) + ': ' + sums[field][0] + ' / ' + collector.format(false, column, null, data) + ': ' + sums[field][1];
        } else if (column.type === 'number' && !isNaN(sums[field]) && !Number.isInteger(sums[field])) {
          var parsedSum = parseFloat(sums[field]);
          var sumToFixed = parsedSum.toFixed(2);
          sumRow[field] = collector.format(sumToFixed, column, null, data);
        } else if (column.type === 'dropdown_values') {
          sums[field].forEach(function (opt) {
            dropdownSums[opt] = (dropdownSums[opt] || 0) + 1;
          });
          sumRow[field] = collector.format(dropdownSums, column, null, data, pdfSumrow, form);
        } else {
          sumRow[field] = collector.format(sums[field], column, null, data, pdfSumrow);
        }
      } else {
        sumRow[field] = '';
      }
    });
    return [sumRow];
  }
});