define("ember-svg-jar/inlined/View", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 3.188A2.821 2.821 0 006.187 6h-.093C4.994 6 3.8 6.987 3.5 8.188L.687 15.311a5.256 5.256 0 00-.5 2.188c0 2.9 2.413 5.313 5.313 5.313s5.313-2.413 5.313-5.313c0-.32-.039-.633-.094-.938.515.728 1.358 1.25 2.281 1.25s1.766-.522 2.281-1.25a5.216 5.216 0 00-.094.938c0 2.9 2.413 5.313 5.313 5.313s5.313-2.413 5.313-5.313c0-.775-.194-1.52-.5-2.188l-.032-.03L22.5 8.186C22.2 6.987 21.006 6 19.906 6h-.093A2.821 2.821 0 0017 3.187c-.757 0-1.434.325-1.938.813h-4.124c-.505-.488-1.18-.813-1.938-.813zm4 9zm0 1c1 0 1.813.812 1.813 1.812S14 16.813 13 16.813 11.187 16 11.187 15 12 13.187 13 13.187zM5.5 14C7.4 14 9 15.6 9 17.5S7.4 21 5.5 21 2 19.4 2 17.5 3.6 14 5.5 14zm15 0c1.9 0 3.5 1.6 3.5 3.5S22.4 21 20.5 21 17 19.4 17 17.5s1.6-3.5 3.5-3.5zm-10.469.813c.072.084.11.152.156.218 0 .031-.001.063 0 .094-.054-.106-.095-.211-.156-.313zM3.5 16c-.2.4-.406.906-.406 1.406 0 1.4 1.193 2.594 2.594 2.594.6 0 1.1-.2 1.5-.5h-.282A3.382 3.382 0 013.5 16.094V16zm15 0c-.2.4-.406.906-.406 1.406 0 1.4 1.194 2.594 2.593 2.594.6 0 1.1-.2 1.5-.5h-.28a3.382 3.382 0 01-3.407-3.406V16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});