define("@movenium/ember-movenium/utils/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sortBy = sortBy;

  /**
   * Sorts options based on given value (options can be array of objects or record)
   * @function sortBy
   * @param {String} sort
   * @param {any} options
   * @param {Array} subformFields
   */
  function sortBy(sort, options) {
    var subformFields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    options = Ember.typeOf(options) !== 'array' ? options.toArray() : options;

    if (sort !== 'all') {
      options.sort(function (a, b) {
        return ('' + a[sort]).localeCompare(b[sort]);
      });
    } else {
      options.sort(function (a, b) {
        if (a[subformFields[0]] > b[subformFields[0]]) return 1;
        if (a[subformFields[0]] < b[subformFields[0]]) return -1;

        if (subformFields[1]) {
          if (a[subformFields[1]] > b[subformFields[1]]) return 1;
          if (a[subformFields[1]] < b[subformFields[1]]) return -1;
        }

        if (subformFields[2]) {
          if (a[subformFields[2]] > b[subformFields[2]]) return 1;
          if (a[subformFields[2]] < b[subformFields[2]]) return -1;
        }

        return 0;
      });
    }

    return options;
  }

  var _default = {
    sortBy: sortBy
  };
  _exports.default = _default;
});