define("ember-table/components/ember-th/component", ["exports", "ember-table/components/-private/base-table-cell", "ember-table/-private/utils/element", "ember-table/components/ember-th/template"], function (_exports, _baseTableCell, _element, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var COLUMN_INACTIVE = 0;
  var COLUMN_RESIZING = 1;
  var COLUMN_REORDERING = 2;
  /**
    The table header cell component. This component manages header cell level
    concerns, and yields the column value and column meta data objects.
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{columns}} as |h|>
        <h.row as |r|>
          <r.cell as |columnValue columnMeta|>
  
          </r.cell>
        </h.row>
      </t.head>
  
      <t.body @rows={{rows}} />
    </EmberTable>
    ```
    @yield {object} columnValue - The column definition
    @yield {object} columnMeta - The meta object associated with this column
    @class {{ember-th}}
    @public
  */

  var _default = _baseTableCell.default.extend({
    layout: _template.default,
    tagName: 'th',
    attributeBindings: ['columnSpan:colspan', 'rowSpan:rowspan'],
    classNameBindings: ['isSortable', 'isResizable', 'isReorderable'],

    /**
      The API object passed in by the table row
      @argument api
      @required
      @type object
    */
    api: null,

    /**
      Action sent when the user clicks right this element
      @argument onContextMenu
      @type Action?
    */
    onContextMenu: null,
    columnValue: Ember.computed.readOnly('api.columnValue'),
    columnMeta: Ember.computed.readOnly('api.columnMeta'),

    /**
      Any sorts applied to the table.
    */
    sorts: Ember.computed.readOnly('api.sorts'),

    /**
      Whether or not the column is sortable. Is true IFF the column is a leaf node
      onUpdateSorts is set on the thead.
    */
    isSortable: Ember.computed.readOnly('columnMeta.isSortable'),

    /**
      Indicates if this column can be resized.
    */
    isResizable: Ember.computed.readOnly('columnMeta.isResizable'),

    /**
     Indicates if this column can be reordered.
    */
    isReorderable: Ember.computed.readOnly('columnMeta.isReorderable'),
    columnSpan: Ember.computed.readOnly('columnMeta.columnSpan'),
    rowSpan: Ember.computed.readOnly('columnMeta.rowSpan'),

    /**
      A variable used for column resizing & ordering. When user press mouse at a point that's close
      to column boundary (using some threshold), this variable set whether it's the left or right
      column.
    */
    _columnState: COLUMN_INACTIVE,

    /**
      An object that listens to touch/ press/ drag events.
    */
    _hammer: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('columnMeta').registerElement(this.element);
      var hammer = new Hammer(this.element);
      hammer.add(new Hammer.Press({
        time: 0
      }));
      hammer.on('press', this.pressHandler.bind(this));
      hammer.on('panstart', this.panStartHandler.bind(this));
      hammer.on('panmove', this.panMoveHandler.bind(this));
      hammer.on('panend', this.panEndHandler.bind(this));
      this._hammer = hammer;
    },
    willDestroyElement: function willDestroyElement() {
      var hammer = this._hammer;
      hammer.off('press');
      hammer.off('panstart');
      hammer.off('panmove');
      hammer.off('panend');
      hammer.destroy();

      this._super.apply(this, arguments);
    },
    actions: {
      sendDropdownAction: function sendDropdownAction() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.sendAction.apply(this, ['onDropdownAction'].concat(args));
      }
    },
    click: function click(event) {
      var isSortable = this.get('isSortable');
      var inputParent = (0, _element.closest)(event.target, 'button:not(.et-sort-toggle), input, label, a, select');

      if (this._columnState === COLUMN_INACTIVE && !inputParent && isSortable) {
        var toggle = event.ctrlKey || event.metaKey;
        this.updateSort({
          toggle: toggle
        });
      }
    },
    contextMenu: function contextMenu(event) {
      this.sendAction('onContextMenu', event);
      return false;
    },
    keyUp: function keyUp(event) {
      var isSortable = this.get('isSortable');
      var inputParent = (0, _element.closest)(event.target, 'button:not(.et-sort-toggle), input, label, a, select');

      if (this._columnState === COLUMN_INACTIVE && !inputParent && event.key === 'Enter' && isSortable) {
        this.updateSort();
      }
    },
    updateSort: function updateSort(_ref) {
      var toggle = _ref.toggle;
      var valuePath = this.get('columnValue.valuePath');
      var sorts = this.get('sorts');
      var existingSorting = sorts.find(function (s) {
        return Ember.get(s, 'valuePath') === valuePath;
      });
      var newSortings = toggle ? sorts.filter(function (s) {
        return Ember.get(s, 'valuePath') !== valuePath;
      }) : [];

      if (!existingSorting) {
        newSortings.push({
          valuePath: valuePath,
          isAscending: false
        });
      } else if (existingSorting.isAscending === false) {
        newSortings.push({
          valuePath: valuePath,
          isAscending: true
        });
      }

      this.get('api').sendUpdateSort(newSortings);
    },
    pressHandler: function pressHandler(event) {
      var _event$pointers = _slicedToArray(event.pointers, 1),
          _event$pointers$ = _event$pointers[0],
          clientX = _event$pointers$.clientX,
          target = _event$pointers$.target;

      this._originalClientX = clientX;
      this._originalTargetWasResize = target.classList.contains('et-header-resize-area');
    },
    panStartHandler: function panStartHandler(event) {
      var isResizable = this.get('isResizable');
      var isReorderable = this.get('isReorderable');

      var _event$pointers2 = _slicedToArray(event.pointers, 1),
          clientX = _event$pointers2[0].clientX;

      if (isResizable && this._originalTargetWasResize) {
        this._columnState = COLUMN_RESIZING;
        this.get('columnMeta').startResize(this._originalClientX);
      } else if (isReorderable) {
        this._columnState = COLUMN_REORDERING;
        this.get('columnMeta').startReorder(clientX);
      }
    },
    panMoveHandler: function panMoveHandler(event) {
      var _event$pointers3 = _slicedToArray(event.pointers, 1),
          clientX = _event$pointers3[0].clientX;

      if (this._columnState === COLUMN_RESIZING) {
        this.get('columnMeta').updateResize(clientX);
        this._prevClientX = clientX;
      } else if (this._columnState === COLUMN_REORDERING) {
        this.get('columnMeta').updateReorder(clientX);
        this._columnState = COLUMN_REORDERING;
      }
    },
    panEndHandler: function panEndHandler() {
      var _this = this;

      if (this._columnState === COLUMN_RESIZING) {
        this.get('columnMeta').endResize();
      } else if (this._columnState === COLUMN_REORDERING) {
        this.get('columnMeta').endReorder();
      }

      Ember.run.next(function () {
        return _this._columnState = COLUMN_INACTIVE;
      });
    }
  });

  _exports.default = _default;
});