define("ember-svg-jar/inlined/ReuseFilled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M36.5 5A4.505 4.505 0 0032 9.5c0 2.481 2.019 4.5 4.5 4.5S41 11.981 41 9.5 38.981 5 36.5 5zM23.531 6.031c-.79.047-1.608.26-2.375.657-4.02 2.009-8.786 4.552-9.843 5.187-2.116 1.375-3.788 2.858-4 6.031-.21 3.28-2.125 23.694-2.125 23.907 0 1.267 1.054 2.218 2.218 2.218 1.27 0 2.113-.844 2.219-2.218l2.344-17.438 3.812 17.531c.317 1.375 1.055 2.125 2.219 2.125 1.269 0 2.323-.724 2.219-2.312 0-.105-3.719-22.438-3.719-22.438l9.844-5.187L29 20h4.688l-4.5-10.438c-1.033-2.3-3.287-3.67-5.657-3.53zM27 22a1 1 0 00-1 1v2h18v-2a1 1 0 00-1-1H27zm-.844 5l2.563 14.5c.295 1.475 1.508 2.5 2.968 2.5h6.625c1.46 0 2.671-1.034 2.97-2.531L43.843 27H26.156z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});