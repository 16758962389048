define("@movenium/ember-movenium/utils/gui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isIE = _exports.isAndroid = _exports.default = void 0;
  _exports.isMobile = isMobile;
  _exports.modalUrl = void 0;

  /**
   * Provides functions and properties to deal with the gui
   *
   * @module gui
   */
  function isMobile() {
    var width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 760;
    return window.matchMedia('only screen and (max-width: ' + width + 'px)').matches;
  }
  /**
   * Is the user's browser Internet Explorer?
   * @const isIE
   * @type {Boolean}
   */


  var isIE = function () {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) return true;
    return false;
  }();
  /**
   * Is the device an Android phone?
   * @const isAndroid
   * @type {Boolean}
   */


  _exports.isIE = isIE;

  var isAndroid = function () {
    return window.cordova && device.platform === 'Android';
  }();
  /**
   * Returns the modal url from a url
   * @function modalUrl
   * @param {String} url
   * @return {String}
   */


  _exports.isAndroid = isAndroid;

  var modalUrl = function modalUrl(url) {
    var base = window.location.href;
    var split = base.split('?');
    if (split[1] && split[1].substr(0, 6) === 'modal=') delete split[1];
    url = encodeURIComponent(url);
    url = url.replace('%3D', '=');
    if (url.startsWith('http')) return url;
    return split[0] + '?' + (split[1] != null ? split[1] + '&' + url : url);
  }; // list all exports here


  _exports.modalUrl = modalUrl;
  var _default = {
    isMobile: isMobile,
    isIE: isIE,
    isAndroid: isAndroid,
    modalUrl: modalUrl
  };
  _exports.default = _default;
});