define("ember-svg-jar/inlined/PlusMath", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans\" d=\"M15 5v10H5v2h10v10h2V17h10v-2H17V5h-2z\" color=\"#000\" overflow=\"visible\" font-family=\"Bitstream Vera Sans\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "width": "26",
      "height": "26"
    }
  };
  _exports.default = _default;
});