define("@movenium/ember-movenium/helpers/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function add(params
  /*, hash*/
  ) {
    return params.reduce(function (a, b) {
      return a + b;
    });
  });

  _exports.default = _default;
});