define("ember-google-charts/utils/render-chart", ["exports", "ember-google-charts/utils/get-visualization-name", "ember-google-charts/utils/format-data"], function (_exports, _getVisualizationName, _formatData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = renderChart;

  function renderChart(element, _ref) {
    var data = _ref.data,
        type = _ref.type,
        _ref$design = _ref.design,
        design = _ref$design === void 0 ? 'classic' : _ref$design,
        _ref$options = _ref.options,
        options = _ref$options === void 0 ? {} : _ref$options;
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var _window = window,
          _window$google = _window.google,
          charts = _window$google.charts,
          visualization = _window$google.visualization;
      var capitalizedType = Ember.String.capitalize(type);
      var isAsyncChart = type === 'geo';
      var isMaterialChart = design === 'material';
      var constructorName = isMaterialChart ? capitalizedType : (0, _getVisualizationName.default)(type);
      var googlePackage = isMaterialChart ? charts : visualization;
      var chartConstructor = googlePackage[constructorName];

      function formatOptions(options) {
        return isMaterialChart ? charts[capitalizedType].convertOptions(options) : options;
      }
      /* Create the chart */


      var chart = new chartConstructor(element);
      /* For charts that are are created asyncronously, listen for the
      ready event */

      if (isAsyncChart) {
        visualization.events.addListener(chart, 'ready', function () {
          resolve(chart);
        });
      }
      /* Make sure errors in render are caught */


      visualization.events.addListener(chart, 'error', reject);
      /* Make the Google Chart object publically available (e.g. for use in tests) */

      element.chart = chart;
      /* Render the chart */

      chart.draw((0, _formatData.default)(data), formatOptions(options));

      if (!isAsyncChart) {
        resolve(chart);
      }
    });
  }
});