define("ember-google-charts/utils/format-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatData;

  function formatData(data) {
    var _window = window,
        visualization = _window.google.visualization;
    var isDataTable = data instanceof visualization.DataTable;
    return isDataTable ? data : visualization.arrayToDataTable(data);
  }
});