define("@movenium/ember-movenium/utils/validations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isVisible = isVisible;
  _exports.validateEmail = validateEmail;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function validateEmail(email) {
    var regExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i;
    return regExp.test(email);
  }

  function isVisible(record, options) {
    if (_typeof(options) !== 'object') return true;
    if (!record) return true;
    var visible = false;
    options.forEach(function (option) {
      Object.keys(option).forEach(function (key) {
        var listString = option[key].toString();
        var value = record[key];
        visible = valueIsInList(value, listString);
      });
    });
    return visible;
  }

  function valueIsInList(value, listString) {
    if (listString === 'true') {
      if (value === 'true' || value === true) return true;else return false;
    }

    if (listString === 'false') {
      if (value === 'true' || value === true) return false;
      return true;
    }

    var negation = false;

    if (listString.substring(0, 1) === '!') {
      listString = listString.substring(1);
      negation = true;
    }

    var list = listString.split(',');
    if (value) value = value.toString();
    var found = list.includes(value);

    if (list.includes('(empty)')) {
      found = Ember.isEmpty(value);
    }

    return negation ? !found : found;
  }

  var _default = {
    validateEmail: validateEmail,
    isVisible: isVisible
  };
  _exports.default = _default;
});