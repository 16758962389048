define("@movenium/ember-movenium/helpers/htmlsafe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function makeHtmlSafe(params
  /*, hash*/
  ) {
    return Ember.String.htmlSafe(params[0]);
  });

  _exports.default = _default;
});