define("ember-svg-jar/inlined/Date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M41 11h-2V7h-4v4H19V7h-4v4h-2a4 4 0 00-4 4v28a4 4 0 004 4h28a4 4 0 004-4V15a4 4 0 00-4-4zm0 32H13V21h28v22zm-4-14H27v10h10V29z\" fill=\"#757575\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "30",
      "height": "30",
      "viewBox": "0 0 54.001 54.001"
    }
  };
  _exports.default = _default;
});