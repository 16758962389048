define("ember-svg-jar/inlined/Cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.736 19.64l-2.098 2.096a.986.986 0 01-1.396 0l-5.241-5.239-5.241 5.238a.986.986 0 01-1.397-.002L4.264 19.64a.99.99 0 010-1.398L9.505 13l-5.24-5.24a.992.992 0 010-1.398l2.098-2.097a.984.984 0 011.397 0L13 9.506l5.242-5.241a.983.983 0 011.396 0l2.098 2.094a.993.993 0 01.001 1.401L16.496 13l5.24 5.241a.992.992 0 010 1.399z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "25",
      "height": "25",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});