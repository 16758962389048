define("ember-shepherd/utils/buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeButton = makeButton;

  /**
   * Creates a button of the specified type, with the given classes and text
   *
   * @function makeButton
   * @param {object} [button] Description here
   * @param {string} [button.type] The type of button cancel, back, or next
   * @param {classes} [button.classes] Classes to apply to the button
   * @param {string} [button.text] The text for the button
   * @param {action} [button.action] The action to call
   * @private
   * @return {{action: *, classes: *, text: *}} Description here
   */
  function makeButton(button) {
    var classes = button.classes,
        secondary = button.secondary,
        text = button.text,
        type = button.type;
    var builtInButtonTypes = ['back', 'cancel', 'next'];

    if (!type) {
      return button;
    }

    (false && !(builtInButtonTypes.includes(type)) && Ember.assert("'type' property must be one of 'back', 'cancel', or 'next'", builtInButtonTypes.includes(type)));
    var action = Ember.run.bind(this, function () {
      this[type]();
    });
    return {
      action: action,
      classes: classes,
      secondary: secondary,
      text: text
    };
  }
});