define('ember-purify/helpers/purify-dom', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var isPresent = Ember.isPresent,
        merge = Ember.merge,
        Helper = Ember.Helper,
        htmlSafe = Ember.String.htmlSafe;
    var _DOMPurify = DOMPurify,
        sanitize = _DOMPurify.sanitize;
    exports.default = Helper.extend({
        compute: function compute(_ref, _ref2) {
            var _ref3 = _slicedToArray(_ref, 1),
                _ref3$ = _ref3[0],
                text = _ref3$ === undefined ? '' : _ref3$;

            var localConfig = _ref2.config,
                _ref2$overrideConfig = _ref2.overrideConfig,
                overrideConfig = _ref2$overrideConfig === undefined ? false : _ref2$overrideConfig;

            var purifyConfig = this.get('config');

            if (isPresent(localConfig)) {
                if (overrideConfig) {
                    purifyConfig = localConfig;
                } else {
                    purifyConfig = merge(localConfig, purifyConfig);
                }
            }

            return htmlSafe(sanitize(text, purifyConfig));
        }
    });
});