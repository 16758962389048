define('ember-power-calendar-utils/index', ['exports', 'luxon'], function (exports, _luxon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;
  exports.formatDate = formatDate;
  exports.startOf = startOf;
  exports.endOf = endOf;
  exports.weekday = weekday;
  exports.isoWeekday = isoWeekday;
  exports.getWeekdaysShort = getWeekdaysShort;
  exports.getWeekdaysMin = getWeekdaysMin;
  exports.getWeekdays = getWeekdays;
  exports.isAfter = isAfter;
  exports.isBefore = isBefore;
  exports.isSame = isSame;
  exports.isBetween = isBetween;
  exports.diff = diff;
  exports.normalizeDate = normalizeDate;
  exports.normalizeRangeActionValue = normalizeRangeActionValue;
  exports.normalizeMultipleActionValue = normalizeMultipleActionValue;
  exports.normalizeCalendarDay = normalizeCalendarDay;
  exports.withLocale = withLocale;
  exports.normalizeCalendarValue = normalizeCalendarValue;
  exports.normalizeDuration = normalizeDuration;
  exports.getDefaultLocale = getDefaultLocale;
  exports.localeStartOfWeek = localeStartOfWeek;
  exports.startOfWeek = startOfWeek;
  exports.endOfWeek = endOfWeek;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  /* Sadly the day in which weeks start is not part of the Intl API, so I need to harcode them */
  var WEEK_STARTS = {
    af: 1,
    ar: 6,
    'ar-dz': 0,
    'ar-kw': 0,
    'ar-ly': 6,
    'ar-ma': 6,
    'ar-sa': 0,
    'ar-tn': 1,
    az: 1,
    be: 1,
    bg: 1,
    bm: 1,
    bn: 0,
    bo: 0,
    br: 1,
    bs: 1,
    ca: 1,
    cs: 1,
    cv: 1,
    cy: 1,
    da: 1,
    de: 1,
    'de-at': 1,
    'de-ch': 1,
    dv: 7,
    el: 1,
    en: 0,
    'en-au': 1,
    'en-ca': 0,
    'en-gb': 1,
    'en-ie': 1,
    'en-il': 0,
    'en-nz': 1,
    eo: 1,
    es: 1,
    'es-do': 1,
    'es-us': 0,
    et: 1,
    eu: 1,
    fa: 6,
    fi: 1,
    fo: 1,
    fr: 1,
    'fr-ca': 0,
    'fr-ch': 1,
    fy: 1,
    gd: 1,
    gl: 1,
    'gom-latn': 1,
    gu: 0,
    he: 0,
    hi: 0,
    hr: 1,
    hu: 1,
    'hy-am': 1,
    id: 1,
    is: 1,
    it: 1,
    ja: 0,
    jv: 1,
    ka: 1,
    kk: 1,
    km: 1,
    kn: 0,
    ko: 0,
    ky: 1,
    lb: 1,
    lo: 0,
    lt: 1,
    lv: 1,
    me: 1,
    mi: 1,
    mk: 1,
    ml: 0,
    mn: 0,
    mr: 0,
    ms: 1,
    'ms-my': 1,
    mt: 1,
    my: 1,
    nb: 1,
    ne: 0,
    nl: 1,
    'nl-be': 1,
    nn: 1,
    'pa-in': 0,
    pl: 1,
    pt: 1,
    'pt-br': 0,
    ro: 1,
    ru: 1,
    sd: 1,
    se: 1,
    si: 0,
    sk: 1,
    sl: 1,
    sq: 1,
    sr: 1,
    'sr-cyrl': 1,
    ss: 1,
    sv: 1,
    sw: 1,
    ta: 0,
    te: 0,
    tet: 1,
    tg: 1,
    th: 0,
    'tl-ph': 1,
    tlh: 1,
    tr: 1,
    tzl: 1,
    tzm: 6,
    'tzm-latn': 6,
    'ug-cn': 1,
    uk: 1,
    ur: 1,
    uz: 1,
    'uz-latn': 1,
    vi: 1,
    yo: 1,
    'zh-cn': 1,
    'zh-hk': 0,
    'zh-tw': 0
  };

  function _getWeekdays(dayFormat) {
    var result = [];
    var formatter = new Intl.DateTimeFormat(_luxon.Settings.defaultLocale, {
      weekday: dayFormat,
      timeZone: 'UTC'
    });
    for (var i = 4; i <= 10; i++) {
      var dt = _luxon.DateTime.utc(1970, 1, i);
      result.push(formatter.format(dt));
    }
    return result;
  }

  function add(date, quantity, unit) {
    return _luxon.DateTime.fromJSDate(date).plus(_defineProperty({}, unit, quantity)).toJSDate();
  }

  function formatDate(date, format) {
    var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    var datetime = date instanceof _luxon.DateTime ? date : _luxon.DateTime.fromJSDate(date);
    if (locale) {
      datetime = datetime.setLocale(locale);
    }
    format = format.replace('YYYY', 'yyyy');
    format = format.replace('dddd', 'EEEE');
    format = format.replace('ddd', 'EEE');
    format = format.replace('DD', 'dd');
    return datetime.toFormat(format);
  }

  function startOf(date, unit) {
    var datetime = _luxon.DateTime.fromJSDate(date);
    if (unit === 'week') {
      var normalizedLocale = datetime.locale.toLowerCase();
      var _weekday = WEEK_STARTS[normalizedLocale];
      if (_weekday === undefined) {
        var parentLocaleStart = WEEK_STARTS[normalizedLocale.slice(0, 2)];
        _weekday = parentLocaleStart === undefined ? 0 : parentLocaleStart; // 'es-ar' defaults to 'es'
      }
      datetime = datetime.set({ hour: 0, minute: 0, second: 0, millisecond: 0, weekday: _weekday });
    } else {
      datetime = datetime.startOf(unit);
    }
    return datetime.toJSDate();
  }

  function endOf(date, unit) {
    return _luxon.DateTime.fromJSDate(date).endOf(unit).toJSDate();
  }

  function weekday(date) {
    return _luxon.DateTime.fromJSDate(date).weekday;
  }

  function isoWeekday(date) {
    var dayStr = _luxon.DateTime.fromJSDate(date).toISOWeekDate().split('-')[2];
    return parseInt(dayStr, 10);
  }

  function getWeekdaysShort() {
    return _getWeekdays('short');
  }

  function getWeekdaysMin() {
    return _getWeekdays('narrow');
  }

  function getWeekdays() {
    return _getWeekdays('long');
  }

  function isAfter(date1, date2) {
    return +date1 > +date2;
  }

  function isBefore(date1, date2) {
    return +date1 < +date2;
  }

  function isSame(date1, date2, unit) {
    var dt1 = _luxon.DateTime.fromJSDate(date1);
    var dt2 = _luxon.DateTime.fromJSDate(date2);
    return dt1.hasSame(dt2, unit);
  }

  function isBetween(date, start, end) {
    return +start <= +date && +date <= +end;
  }

  function diff(date1, date2) {
    return _luxon.DateTime.fromJSDate(date1).diff(_luxon.DateTime.fromJSDate(date2));
  }

  function normalizeDate(dateOrDatetime) {
    if (dateOrDatetime === undefined || dateOrDatetime === null || dateOrDatetime instanceof Date) {
      return dateOrDatetime;
    } else {
      return dateOrDatetime.toJSDate();
    }
  }

  function normalizeRangeActionValue(val) {
    return {
      date: val.date,
      datetime: {
        start: val.date.start ? _luxon.DateTime.fromJSDate(val.date.start) : val.date.start,
        end: val.date.end ? _luxon.DateTime.fromJSDate(val.date.end) : val.date.end
      }
    };
  }

  function normalizeMultipleActionValue(val) {
    return {
      date: val.date,
      datetime: val.date ? val.date.map(function (e) {
        return _luxon.DateTime.fromJSDate(e);
      }) : val.date
    };
  }

  function normalizeCalendarDay(day) {
    day.datetime = _luxon.DateTime.fromJSDate(day.date);
    return day;
  }

  function withLocale(locale, fn) {
    var returnValue = void 0;
    if (locale) {
      var previousLocale = _luxon.Settings.defaultLocale;
      _luxon.Settings.defaultLocale = locale;
      returnValue = fn();
      _luxon.Settings.defaultLocale = previousLocale;
    } else {
      returnValue = fn();
    }
    return returnValue;
  }

  function normalizeCalendarValue(value) {
    if (value) {
      return { date: value.date, datetime: value.date ? _luxon.DateTime.fromJSDate(value.date) : undefined };
    }
    return { date: undefined, datetime: undefined };
  }

  var DURATION_UNITS = {
    y: 'years',
    M: 'months',
    w: 'weeks',
    d: 'days',
    h: 'hours',
    m: 'minutes',
    s: 'seconds'
  };

  function normalizeDuration(value) {
    if (value === null) {
      return null;
    }
    if (value instanceof _luxon.Duration) {
      return value.valueOf();
    }
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string") {
      var _value$match = value.match(/(\d+)(.*)/),
          _value$match2 = _slicedToArray(_value$match, 3),
          quantity = _value$match2[1],
          units = _value$match2[2];

      units = units.trim() || "days";
      units = DURATION_UNITS[units] || units;
      var duration = _luxon.Duration.fromObject(_defineProperty({}, units, parseInt(quantity, 10)));
      return duration.valueOf();
    }
  }

  function getDefaultLocale() {
    return _luxon.Settings.defaultLocale || _luxon.DateTime.local().resolvedLocaleOpts().locale;
  }

  function localeStartOfWeek(locale) {
    var now = new Date();
    var day = withLocale(locale, function () {
      return formatDate(startOf(now, 'week'), 'dddd');
    });
    var idx = withLocale(locale, getWeekdays).indexOf(day);
    return idx >= 0 ? idx : 0;
  }

  function startOfWeek(day, startOfWeek) {
    while (isoWeekday(day) % 7 !== startOfWeek) {
      day = add(day, -1, "day");
    }
    return day;
  }

  function endOfWeek(day, startOfWeek) {
    var eow = (startOfWeek + 6) % 7;
    while (isoWeekday(day) % 7 !== eow) {
      day = add(day, 1, "day");
    }
    return day;
  }
});