define("ember-svg-jar/inlined/Lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13 0C9.676 0 7 2.676 7 6v4c-2.2 0-4 1.8-4 4v8c0 2.2 1.8 4 4 4h12c2.2 0 4-1.8 4-4v-8c0-2.2-1.8-4-4-4V6c0-3.324-2.676-6-6-6zm0 2c2.276 0 4 1.724 4 4v4H9V6c0-2.276 1.724-4 4-4zm0 13c1.1 0 2 .9 2 2 0 .7-.4 1.387-1 1.688V21c0 .6-.4 1-1 1s-1-.4-1-1v-2.313c-.6-.3-1-.987-1-1.687 0-1.1.9-2 2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 26 26",
      "width": "25",
      "height": "25"
    }
  };
  _exports.default = _default;
});