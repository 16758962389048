define("ember-google-charts/services/google-charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    language: 'en',
    init: function init() {
      this._super.apply(this, arguments);

      this.googlePackages = this.googlePackages || ['corechart', 'bar', 'line', 'scatter'];
      this.defaultOptions = this.defaultOptions || {
        animation: {
          duration: 500,
          startup: false
        }
      };
    },
    loadPackages: function loadPackages() {
      var _this = this;

      var _window = window,
          _window$google = _window.google,
          charts = _window$google.charts,
          visualization = _window$google.visualization;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (visualization !== undefined) {
          resolve();
        } else {
          charts.load('current', {
            language: _this.language,
            packages: _this.googlePackages
          });
          charts.setOnLoadCallback(function (ex) {
            if (ex) {
              reject(ex);
            }

            resolve();
          });
        }
      });
    }
  });

  _exports.default = _default;
});