define("ember-toggle/components/x-toggle-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c6fD2cYM",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[4,\"if\",[[23,0,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",false],[12,\"for\",[23,0,[\"for\"]]],[12,\"class\",[29,[\"toggle-text toggle-prefix \",[23,0,[\"labelType\"]]]]],[12,\"onclick\",[28,\"action\",[[23,0,[]],\"clickLabel\"],null]],[13,2],[8],[0,\" \\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[23,0,[\"label\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,0,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-label/template.hbs"
    }
  });

  _exports.default = _default;
});