define("ember-sortable/components/sortable-item", ["exports", "ember-sortable/system/scroll-parent", "ember-sortable/system/scroll-container", "ember-sortable/templates/components/sortable-item", "ember-sortable/utils/css-calculation", "ember-sortable/utils/constant", "ember-sortable/utils/coordinate", "ember-test-waiters"], function (_exports, _scrollParent, _scrollContainer, _sortableItem, _cssCalculation, _constant, _coordinate, _emberTestWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var sortableItemWaiter = (0, _emberTestWaiters.buildWaiter)("sortable-item-waiter");
  /**
   * This component represents an individual model of `sortable-group`.
   *
   * Public API
   * @param {Ember.Object} group the group that this model belongs to.
   * @param {String} handle the attached handle, by default, it looks for a `sortable-handle`.
   * @param {Function}  model the model that this component is associated with.
   * @param {Function}  [onDragStart] An optional callback for when dragging starts.
   * @param {Function}  [onDragStop] An optional callback for when dragging stops.
   *
   * @module drag-drop/-private/draggable-item
   */

  var _default = Ember.Component.extend({
    layout: _sortableItem.default,
    tagName: 'li',
    classNames: ['sortable-item'],
    classNameBindings: ['isDragging', 'isDropping'],
    attributeBindings: ['data-test-selector', 'tabindex'],

    /**
      Group to which the item belongs.
      @property group
      @type SortableGroup
      @default null
    */
    group: null,

    /**
      Model which the item represents.
      @property model
      @type Object
      @default null
    */
    model: null,

    /**
      Selector for the element to use as handle.
      1. By default, we will hook it the yielded sortable-handle.
      2. If you don't use the sortable-handle, the entire element will be used as the handle.
      3. In very rare situations, if you want to use a handle, but not the sortable-handle,
         you can override this class with your own handle's selector. This behavior will be
         synonymous with v1
      @property handle
      @type String
      @default "[data-sortable-handle]"
    */
    handle: "[data-sortable-handle]",

    /**
     * Tolerance, in pixels, for when sorting should start.
     * If specified, sorting will not start until after mouse
     * is dragged beyond distance. Can be used to allow for clicks
     * on elements within a handle.
     *
     * @property distance
     * @type Integer
     * @default 0
     */
    distance: 0,

    /**
     * True if the item is currently being dragged.
     *
     * @property isDragging
     * @type Boolean
     * @default false
     * @protected
     */
    isDragging: false,

    /**
      Action that fires when the item starts being dragged.
      @property onDragStart
      @type Action
      @param object item model
      @default null
    */
    onDragStart: function onDragStart() {},

    /**
      Action that fires when the item stops being dragged.
      @property onDragStop
      @type Action
      @param object item model
      @default null
    */
    onDragStop: function onDragStop() {},

    /**
      True if the item is currently dropping.
      @property isDropping
      @type Boolean
      @default false
    */
    isDropping: false,

    /**
      True if the item was dropped during the interaction
      @property wasDropped
      @type Boolean
      @default false
    */
    wasDropped: false,

    /**
      @property isBusy
      @type Boolean
    */
    isBusy: Ember.computed.or('isDragging', 'isDropping'),

    /**
      The frequency with which the group is informed
      that an update is required.
      @property updateInterval
      @type Number
      @default 125
    */
    updateInterval: 125,

    /**
      Additional spacing between active item and the rest of the elements.
      @property spacing
      @type Number
      @default 0[px]
    */
    spacing: 0,

    /**
      @private
      Allows host instance to use the `group` property for something else with
      minimal overriding.
    */
    _direction: Ember.computed.readOnly('group.direction'),
    init: function init() {
      this._super.apply(this, arguments);

      this._setGetterSetters();
    },

    /**
      @method didInsertElement
    */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // scheduled to prevent deprecation warning:
      // "never change properties on components, services or models during didInsertElement because it causes significant performance degradation"


      Ember.run.schedule("afterRender", this, "_tellGroup", "registerItem", this); // Instead of using `event.preventDefault()` in the 'primeDrag' event,
      // (doesn't work in Chrome 56), we set touch-action: none as a workaround.

      var handleElement = this.element.querySelector(this.get('handle'));

      if (!handleElement) {
        // Handle does not exist, so we set it null here.
        this.set('handle', null);
        handleElement = this.element;
      }

      handleElement.style['touch-action'] = 'none';
    },

    /**
      @method willDestroyElement
    */
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      this._super.apply(this, arguments); // scheduled to prevent deprecation warning:
      // "never change properties on components, services or models during didInsertElement because it causes significant performance degradation"


      Ember.run.schedule("afterRender", this, "_tellGroup", "deregisterItem", this); // remove event listeners that may still be attached

      _constant.DRAG_ACTIONS.forEach(function (event) {
        return window.removeEventListener(event, _this._prepareDragListener);
      });

      _constant.END_ACTIONS.forEach(function (event) {
        return window.removeEventListener(event, _this._cancelStartDragListener);
      });

      this.element.removeEventListener(_constant.ELEMENT_CLICK_ACTION, this._preventClickHandler);
      this.set('isDragging', false);
      this.set('isDropping', false);
    },

    /**
      @method mouseDown
    */
    mouseDown: function mouseDown(event) {
      if (event.which !== 1) {
        return;
      }

      if (event.ctrlKey) {
        return;
      }

      this._primeDrag(event);
    },
    keyDown: function keyDown(event) {
      var handleElement = this.element.querySelector(this.get('handle')); // If the event is coming from within the item, we do not want to activate keyboard reorder mode.

      if (event.target === handleElement || event.target === this.element) {
        this.set('group._selectedItem', this);

        this._tellGroup('activateKeyDown');
      } else {
        this._tellGroup('deactivateKeyDown');
      }
    },

    /**
      @method touchStart
    */
    touchStart: function touchStart(event) {
      this._primeDrag(event);
    },

    /**
      @method freeze
    */
    freeze: function freeze() {
      var el = this.element;

      if (!el) {
        return;
      }

      el.style.transition = 'none';
    },

    /**
      @method reset
    */
    reset: function reset() {
      var el = this.element;

      if (!el) {
        return;
      }

      delete this._y;
      delete this._x;
      el.style.transform = '';
    },

    /**
      @method thaw
    */
    thaw: function thaw() {
      var el = this.element;

      if (!el) {
        return;
      }

      el.style.transition = '';
    },

    /**
     * Setup event listeners for drag and drop
     *
     * @method _primeDrag
     * @param {Event} startEvent JS Event object
     * @private
     */
    _primeDrag: function _primeDrag(startEvent) {
      var _this2 = this;

      var handle = this.get('handle');

      if (handle && !startEvent.target.closest(handle)) {
        return;
      }

      startEvent.preventDefault();
      startEvent.stopPropagation();
      this._prepareDragListener = Ember.run.bind(this, this._prepareDrag, startEvent);

      _constant.DRAG_ACTIONS.forEach(function (event) {
        return window.addEventListener(event, _this2._prepareDragListener);
      });

      this._cancelStartDragListener = function () {
        _constant.DRAG_ACTIONS.forEach(function (event) {
          return window.removeEventListener(event, _this2._prepareDragListener);
        });
      };

      var selfCancellingCallback = function selfCancellingCallback() {
        _constant.END_ACTIONS.forEach(function (event) {
          return window.removeEventListener(event, selfCancellingCallback);
        });

        _this2._cancelStartDragListener();
      };

      _constant.END_ACTIONS.forEach(function (event) {
        return window.addEventListener(event, selfCancellingCallback);
      });
    },

    /**
     * Prepares for the drag event
     *
     * @method _prepareDrag
     * @param {Event} startEvent JS Event object
     * @param {Event} event JS Event object
     * @private
     */
    _prepareDrag: function _prepareDrag(startEvent, event) {
      var _this3 = this;

      var distance = this.get('distance');
      var dx = Math.abs((0, _coordinate.getX)(startEvent) - (0, _coordinate.getX)(event));
      var dy = Math.abs((0, _coordinate.getY)(startEvent) - (0, _coordinate.getY)(event));

      if (distance <= dx || distance <= dy) {
        _constant.DRAG_ACTIONS.forEach(function (event) {
          return window.removeEventListener(event, _this3._prepareDragListener);
        });

        this._startDrag(startEvent);
      }
    },

    /**
     * Start dragging & setup more event listeners
     *
     * @method _startDrag
     * @param {Event} event JS Event object
     * @private
     */
    _startDrag: function _startDrag(event) {
      var _this4 = this;

      if (this.get('isBusy')) {
        return;
      }

      var drag = this._makeDragHandler(event);

      var dragThrottled = function dragThrottled(ev) {
        return Ember.run.throttle(_this4, drag, ev, 16, false);
      };

      var drop = function drop() {
        _constant.DRAG_ACTIONS.forEach(function (event) {
          return window.removeEventListener(event, dragThrottled);
        });

        _constant.END_ACTIONS.forEach(function (event) {
          return window.removeEventListener(event, drop);
        });

        Ember.run(function () {
          _this4._drop();
        });
      };

      _constant.DRAG_ACTIONS.forEach(function (event) {
        return window.addEventListener(event, dragThrottled);
      });

      _constant.END_ACTIONS.forEach(function (event) {
        return window.addEventListener(event, drop);
      });

      this._tellGroup('prepare');

      this.set('isDragging', true);
      this.onDragStart(this.get('model'));

      this._scrollOnEdges(drag);
    },

    /**
      The maximum scroll speed when dragging element.
      @property maxScrollSpeed
      @default 20
     */
    maxScrollSpeed: 20,
    _scrollOnEdges: function _scrollOnEdges(drag) {
      var _this5 = this;

      var groupDirection = this.get('_direction');
      var element = this.element;
      var scrollContainer = new _scrollContainer.default((0, _scrollParent.default)(element));
      var itemContainer = {
        width: parseInt(getComputedStyle(element).width, 10),

        get height() {
          return parseInt(getComputedStyle(element).height, 10);
        },

        get left() {
          return element.getBoundingClientRect().left;
        },

        get right() {
          return this.left + this.width;
        },

        get top() {
          return element.getBoundingClientRect().top;
        },

        get bottom() {
          return this.top + this.height;
        }

      };
      var leadingEdgeKey, trailingEdgeKey, scrollKey, pageKey;

      if (groupDirection === 'x') {
        leadingEdgeKey = 'left';
        trailingEdgeKey = 'right';
        scrollKey = 'scrollLeft';
        pageKey = 'pageX';
      } else {
        leadingEdgeKey = 'top';
        trailingEdgeKey = 'bottom';
        scrollKey = 'scrollTop';
        pageKey = 'pageY';
      }

      var createFakeEvent = function createFakeEvent() {
        if (_this5._pageX == null && _this5._pageY == null) {
          return;
        }

        return {
          pageX: _this5._pageX,
          pageY: _this5._pageY
        };
      }; // Set a trigger padding that will start scrolling
      // the box when the item reaches within padding pixels
      // of the edge of the scroll container.


      var checkScrollBounds = function checkScrollBounds() {
        var leadingEdge = itemContainer[leadingEdgeKey];
        var trailingEdge = itemContainer[trailingEdgeKey];
        var scroll = scrollContainer[scrollKey]();
        var delta = 0;

        if (trailingEdge >= scrollContainer[trailingEdgeKey]) {
          delta = trailingEdge - scrollContainer[trailingEdgeKey];
        } else if (leadingEdge <= scrollContainer[leadingEdgeKey]) {
          delta = leadingEdge - scrollContainer[leadingEdgeKey];
        }

        if (delta !== 0) {
          var speed = _this5.get('maxScrollSpeed');

          delta = Math.min(Math.max(delta, -1 * speed), speed);
          delta = scrollContainer[scrollKey](scroll + delta) - scroll;
          var event = createFakeEvent();

          if (event) {
            if (scrollContainer.isWindow) {
              event[pageKey] += delta;
            }

            Ember.run(function () {
              return drag(event);
            });
          }
        }

        if (_this5.get('isDragging')) {
          requestAnimationFrame(checkScrollBounds);
        }
      };

      if (!Ember.testing) {
        requestAnimationFrame(checkScrollBounds);
      }
    },

    /**
      @method _makeDragHandler
      @param {Event} startEvent
      @return {Function}
      @private
    */
    _makeDragHandler: function _makeDragHandler(startEvent) {
      var _this6 = this;

      var groupDirection = this.get('_direction');
      var dragOrigin;
      var elementOrigin;
      var scrollOrigin;
      var parentElement = this.element.parentNode;

      if (groupDirection === 'x') {
        dragOrigin = (0, _coordinate.getX)(startEvent);
        elementOrigin = this.get('x');
        scrollOrigin = parentElement.getBoundingClientRect().left;
        return function (event) {
          _this6._pageX = (0, _coordinate.getX)(event);
          var dx = _this6._pageX - dragOrigin;
          var scrollX = parentElement.getBoundingClientRect().left;
          var x = elementOrigin + dx + (scrollOrigin - scrollX);

          _this6._drag(x);
        };
      }

      if (groupDirection === 'y') {
        dragOrigin = (0, _coordinate.getY)(startEvent);
        elementOrigin = this.get('y');
        scrollOrigin = parentElement.getBoundingClientRect().top;
        return function (event) {
          _this6._pageY = (0, _coordinate.getY)(event);
          var dy = _this6._pageY - dragOrigin;
          var scrollY = parentElement.getBoundingClientRect().top;
          var y = elementOrigin + dy + (scrollOrigin - scrollY);

          _this6._drag(y);
        };
      }
    },

    /**
      @method _tellGroup
      @private
    */
    _tellGroup: function _tellGroup(method) {
      var group = this.get('group');

      if (group) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        group[method].apply(group, args);
      }
    },

    /**
      @method _scheduleApplyPosition
      @private
    */
    _scheduleApplyPosition: function _scheduleApplyPosition() {
      Ember.run.scheduleOnce('render', this, '_applyPosition');
    },

    /**
      @method _applyPosition
      @private
    */
    _applyPosition: function _applyPosition() {
      if (!this.element || !this.element) {
        return;
      }

      var groupDirection = this.get('_direction');

      if (groupDirection === 'x') {
        var x = this.get('x');
        var dx = x - this.element.offsetLeft + parseFloat(getComputedStyle(this.element).marginLeft);
        this.element.style.transform = "translateX(".concat(dx, "px)");
      }

      if (groupDirection === 'y') {
        var y = this.get('y');
        var dy = y - this.element.offsetTop;
        this.element.style.transform = "translateY(".concat(dy, "px)");
      }
    },

    /**
      @method _drag
      @private
    */
    _drag: function _drag(dimension) {
      if (!this.get("isDragging")) {
        return;
      }

      var updateInterval = this.get('updateInterval');
      var groupDirection = this.get('_direction');

      if (groupDirection === 'x') {
        this.set('x', dimension);
      }

      if (groupDirection === 'y') {
        this.set('y', dimension);
      }

      Ember.run.throttle(this, '_tellGroup', 'update', updateInterval);
    },

    /**
      @method _drop
      @private
    */
    _drop: function _drop() {
      var _this7 = this;

      if (!this.element) {
        return;
      }

      var transitionPromise = this._waitForTransition();

      this._preventClick();

      this.set('isDragging', false);
      this.set('isDropping', true);

      this._tellGroup('update');

      transitionPromise.then(function () {
        return _this7._complete();
      });
    },

    /**
      @method _preventClick
      @private
    */
    _preventClick: function _preventClick() {
      var _this8 = this;

      var selfCancellingCallback = function selfCancellingCallback(event) {
        _this8.element.removeEventListener(_constant.ELEMENT_CLICK_ACTION, selfCancellingCallback);

        _this8._preventClickHandler(event);
      };

      this.element.addEventListener(_constant.ELEMENT_CLICK_ACTION, selfCancellingCallback);
    },

    /**
      @method _preventClickHandler
      @private
    */
    _preventClickHandler: function _preventClickHandler(e) {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
    },

    /**
      @method _waitForTransition
      @private
      @return Promise
    */
    _waitForTransition: function _waitForTransition() {
      var _this9 = this;

      var waiterToken;

      if (false
      /* DEBUG */
      ) {
        waiterToken = sortableItemWaiter.beginAsync();
      }

      var transitionPromise;

      if (this.get('isAnimated')) {
        var deferred = Ember.RSVP.defer();
        this.element.addEventListener('transitionend', deferred.resolve);
        transitionPromise = deferred.promise.finally(function () {
          _this9.element.removeEventListener('transitionend', deferred.resolve);
        });
      } else {
        var duration = this.get('isAnimated') ? this.get('transitionDuration') : 200;
        transitionPromise = new Ember.RSVP.Promise(function (resolve) {
          return Ember.run.later(resolve, duration);
        });
      }

      if (false
      /* DEBUG */
      ) {
        transitionPromise = transitionPromise.finally(function () {
          sortableItemWaiter.endAsync(waiterToken);
        });
      }

      return transitionPromise;
    },

    /**
      @method _complete
      @private
    */
    _complete: function _complete() {
      this.onDragStop(this.get('model'));
      this.set('isDropping', false);
      this.set('wasDropped', true);

      this._tellGroup('commit');
    },

    /**
     * Defining getters and setters to support native getter and setters until we decide to drop support for ember versions below 3.10
     */
    _setGetterSetters: function _setGetterSetters() {
      /**
        True if the item transitions with animation.
        @property isAnimated
        @type Boolean
      */
      Ember.defineProperty(this, 'isAnimated', {
        get: function get() {
          if (!this.element) {
            return;
          }

          var el = this.element;
          var property = getComputedStyle(el).transitionProperty;
          return /all|transform/.test(property);
        }
      });
      /**
        The current transition duration in milliseconds.
        @property transitionDuration
        @type Number
      */

      Ember.defineProperty(this, 'transitionDuration', {
        get: function get() {
          var el = this.element;
          var rule = getComputedStyle(el).transitionDuration;
          var match = rule.match(/([\d.]+)([ms]*)/);

          if (match) {
            var value = parseFloat(match[1]);
            var unit = match[2];

            if (unit === 's') {
              value = value * 1000;
            }

            return value;
          }

          return 0;
        }
      });
      /**
        Horizontal position of the item.
        @property x
        @type Number
      */

      Ember.defineProperty(this, 'x', {
        get: function get() {
          if (this._x === undefined) {
            var marginLeft = parseFloat(getComputedStyle(this.element).marginLeft);
            this._x = this.element.scrollLeft + this.element.offsetLeft - marginLeft;
          }

          return this._x;
        },
        set: function set(value) {
          if (value !== this._x) {
            this._x = value;

            this._scheduleApplyPosition();
          }
        }
      });
      /**
        Vertical position of the item relative to its offset parent.
        @property y
        @type Number
      */

      Ember.defineProperty(this, 'y', {
        get: function get() {
          if (this._y === undefined) {
            this._y = this.element.offsetTop;
          }

          return this._y;
        },
        set: function set(value) {
          if (value !== this._y) {
            this._y = value;

            this._scheduleApplyPosition();
          }
        }
      });
      /**
        Width of the item.
        @property height
        @type Number
      */

      Ember.defineProperty(this, 'width', {
        get: function get() {
          var el = this.element;
          var width = el.offsetWidth;
          var elStyles = getComputedStyle(el);
          width += parseInt(elStyles.marginLeft) + parseInt(elStyles.marginRight); // equal to jQuery.outerWidth(true)

          width += (0, _cssCalculation.getBorderSpacing)(el).horizontal;
          return width;
        }
      });
      /**
        Height of the item including margins.
        @property height
        @type Number
      */

      Ember.defineProperty(this, 'height', {
        get: function get() {
          var el = this.element;
          var height = el.offsetHeight;
          var marginBottom = parseFloat(getComputedStyle(el).marginBottom);
          height += marginBottom;
          height += (0, _cssCalculation.getBorderSpacing)(el).vertical;
          return height;
        }
      });
    }
  });

  _exports.default = _default;
});