define("ember-sortable/templates/components/sortable-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jPugfREM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"item\",\"model\"],[[28,\"component\",[\"ember-sortable@sortable-item\"],[[\"group\"],[[23,0,[]]]]],[24,[\"model\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-sortable/templates/components/sortable-group.hbs"
    }
  });

  _exports.default = _default;
});