define("ember-svg-jar/inlined/PlusSign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><filter id=\"a\" x=\"0\" y=\"0\" width=\"75\" height=\"75\" filterUnits=\"userSpaceOnUse\"><feOffset/><feGaussianBlur stdDeviation=\"4\" result=\"blur\"/><feFlood flood-opacity=\".369\"/><feComposite operator=\"in\" in2=\"blur\"/><feComposite in=\"SourceGraphic\"/></filter></defs><g filter=\"url(#a)\"><circle data-name=\"Ellipse 27\" cx=\"25.5\" cy=\"25.5\" r=\"25.5\" transform=\"translate(12 12)\"/></g><path data-name=\"Rectangle 588\" fill=\"#fff\" d=\"M24 36h27v3H24z\"/><path data-name=\"Rectangle 589\" fill=\"#fff\" d=\"M39 24v27h-3V24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "6 6 63 63"
    }
  };
  _exports.default = _default;
});