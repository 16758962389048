define("ember-svg-jar/inlined/DragHandle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(-746 -403)\" fill=\"#737373\"><circle data-name=\"Ellipse 32\" cx=\"1.5\" cy=\"1.5\" r=\"1.5\" transform=\"translate(746 403)\"/><circle data-name=\"Ellipse 32\" cx=\"1.5\" cy=\"1.5\" r=\"1.5\" transform=\"translate(751 403)\"/><circle data-name=\"Ellipse 32\" cx=\"1.5\" cy=\"1.5\" r=\"1.5\" transform=\"translate(746 408)\"/><circle data-name=\"Ellipse 32\" cx=\"1.5\" cy=\"1.5\" r=\"1.5\" transform=\"translate(751 408)\"/><circle data-name=\"Ellipse 32\" cx=\"1.5\" cy=\"1.5\" r=\"1.5\" transform=\"translate(746 413)\"/><circle data-name=\"Ellipse 32\" cx=\"1.5\" cy=\"1.5\" r=\"1.5\" transform=\"translate(751 413)\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "13",
      "viewBox": "0 0 8 13"
    }
  };
  _exports.default = _default;
});