define("@movenium/ember-movenium/services/evented", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * Trigger an event in some component
   *
   * ```js
   *@service evented;
   *
   *this.evented.storeEvent('insert', 'worktime', this.worktimeRecord)
   *```
   * You can listen to these events in another component. Always remember to unsubscribe from the events with .off()
   * ```js
   *
   *@service event;
   *
   *constructor() {
   *   this.evented.on('storeEvent', this, 'onStoreEvent')
   *}
   *
   *function onStoreEvent(params) {
   *   if (params.type === 'worktime') // do something else
   *},
   *
   *willDestroy(){
   *   this.evented.off('storeEvent', this, 'onStoreEvent')
   *}
   * ```
   *
   * @class EventService
   * @constructor
   * @public
  */
  var _default = Ember.Service.extend(Ember.Evented, {
    /**
     * A DS.Store event
     * @method storeEvent
     * @param {String} action the type of action, ie 'remove', 'insert', 'update'
     * @param {String} type type of event that happened ie 'worktime', 'absence', 'myEvent'
     * @param {IRecord} record the record that triggered the event
     * @return {void}
     */
    storeEvent: function storeEvent(action, type, record) {
      this.trigger('storeEvent', {
        action: action,
        type: type,
        record: record
      });
    },

    /**
     * Can be called for example when the application's onResume() is triggered to trigger resumes elsewhere in the application
     * @method resumed
     * @return {void}
     */
    resumed: function resumed() {
      this.trigger('onResumed');
    }
  });

  _exports.default = _default;
});