define('ember-cli-clock/services/clock', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    interval: 1000,
    intervalTime: 1000,
    second: 0,
    minute: 0,
    five: 0,
    quarter: 0,
    hour: 0,
    time: null,

    date: Ember.computed('time', function () {
      return new Date(this.get('time'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.start();
    },
    start: function start() {
      var _this = this;

      this.update();
      this.set('intervalId', window.setInterval(function () {
        return _this.update();
      }, this.get('interval')));
    },
    stop: function stop() {
      window.clearInterval(this.get('intervalId'));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.stop();
    },


    onIntervalChange: Ember.observer('interval', function () {
      this.stop();
      this.start();
    }),

    update: function update() {
      var _this2 = this;

      Ember.run(function () {
        return _this2.set('time', Date.now());
      });
    },
    reset: function reset() {
      this.stop();
      this.setProperties({ second: 0, minute: 0, five: 0, quarter: 0, hour: 0 });
      this.start();
    },


    intervalChange: Ember.observer('intervalTime', function () {
      if (Ember.testing) {
        this.set('interval', this.get('intervalTime'));
        return this.reset();
      }
      throw Error('The clock interval cannot be changed except during testing');
    }),

    timeChange: Ember.observer('time', function () {
      this.tick();
    }),

    tick: function tick() {
      var second = this.incrementProperty('second');

      if (second && second % 60 === 0) {
        var minute = this.incrementProperty('minute');

        if (minute !== 0) {
          if (minute % 5 === 0) {
            this.incrementProperty('five');
          }

          if (minute % 15 === 0) {
            this.incrementProperty('quarter');
          }

          if (minute % 60 === 0) {
            this.incrementProperty('hour');
          }
        }
      }
    }
  });
});