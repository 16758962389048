define("ember-google-charts/utils/render-material-chart", ["exports", "ember-google-charts/utils/render-chart"], function (_exports, _renderChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = renderMaterialChart;

  function renderMaterialChart(data, options) {
    (false && Ember.warn("renderMaterialChart() has been deprecated. Use renderChart() instead. See https://github.com/sir-dunxalot/ember-google-charts#custom-charts", {
      id: 'ember-google-charts.unified-render-util'
    }));
    var design = this.design,
        element = this.element,
        type = this.type;
    return (0, _renderChart.default)(element, {
      data: data,
      design: design,
      options: options,
      type: type
    });
  }
});