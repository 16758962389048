define("ember-svg-jar/inlined/MoveSearch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 .188A9.812 9.812 0 00.187 10 9.812 9.812 0 0010 19.813c2.29 0 4.393-.811 6.063-2.125l.875.875a1.845 1.845 0 00.343 2.156l4.594 4.625c.713.714 1.88.714 2.594 0l.875-.875a1.84 1.84 0 000-2.594l-4.625-4.594a1.824 1.824 0 00-2.157-.312l-.875-.875A9.812 9.812 0 0010 .188zM10 2a8 8 0 110 16 8 8 0 010-16zM4.937 7.469a5.446 5.446 0 00-.812 2.875 5.46 5.46 0 005.469 5.469 5.516 5.516 0 003.156-1 7.166 7.166 0 01-.75.03 7.045 7.045 0 01-7.063-7.062c0-.104-.005-.208 0-.312z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});